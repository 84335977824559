import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { QueryKeys } from '@app/constants/queryKeys';
import {
  getTokensWhitelist,
  POPULAR_BASES
} from '@app/constants/tokensWhitelist';
import { getWhitelistedCoinIcon } from '@app/hooks/swap/helpers';
import { Token } from '@app/types/token';
import _ from 'lodash';
import { ApplicationConfig } from '@app/config/types';
import { useConfig } from '@app/config';
import { useSupportedChain } from '@app/hooks/other/useSupportedChain';

export async function fetcher(config: ApplicationConfig | undefined) {
  const TOKENS_WHITELIST = getTokensWhitelist(config);

  return _.uniqBy(TOKENS_WHITELIST, 'contractAddress')
    .filter(item => POPULAR_BASES.includes(item.symbol))
    .map(item => ({
      ...item,
      logoUri: getWhitelistedCoinIcon(item)
    }));
}

export const usePopularBasesTokens = (
  options: UseQueryOptions<Token[]> = {}
) => {
  const config = useConfig();
  const chainId = useSupportedChain()?.chainId;

  return useQuery<Token[]>(
    [QueryKeys.POPULAR_TOKENS, chainId],
    async () => {
      return await fetcher(config);
    },
    {
      enabled: true,
      staleTime: 30000,
      ...options
    }
  );
};
