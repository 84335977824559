import { motion } from 'framer-motion';
import { FC, PropsWithChildren } from 'react';

const AnimatedLayout: FC<PropsWithChildren> = ({ children }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
  >
    {children}
  </motion.div>
);
export default AnimatedLayout;
