import {
  Box,
  Button,
  Center,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

import Image from 'next/image';

import { Icon } from '@app/components/Icon';
import { useChainExplorer } from '@app/hooks/other/useExplorerAddress';

import success from 'src/assets/images/bg/success.png';
import error from 'src/assets/images/bg/error.png';

interface Props {
  status?: 'success' | 'error';
  title?: string;
  message?: string;
  isOpen: boolean;
  onClose: () => void;
  txHash?: string;
}

export const TransactionResultModal: FC<Props> = ({
  isOpen,
  onClose,
  status,
  title,
  message,
  txHash
}) => {
  const router = useRouter();

  const { explorer } = useChainExplorer();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      isCentered
      motionPreset="slideInBottom"
      size="xs"
    >
      <ModalOverlay />
      <ModalContent sx={{ maxWidth: ['100vw', '586px'], padding: '48px 32px' }}>
        <VStack w="100%" gap={0}>
          <Center w="140px" h="140px" borderRadius="50%">
            <Center
              w="160px"
              h="160px"
              borderRadius="50%"
              sx={{
                position: 'relative'
              }}
            >
              <Image
                fill
                src={status === 'success' ? success : error}
                alt="status"
              />
            </Center>
          </Center>
          <Box mt="24px" w="100%">
            <Text
              textAlign="center"
              w="100%"
              color={status === 'success' ? 'success' : 'error'}
              fontSize="32px"
              fontWeight={500}
              lineHeight="32px"
            >
              {title}
            </Text>
            <Text
              w="100%"
              color="neutral.200"
              fontSize="18px"
              lineHeight="20px"
              fontFamily="p"
              fontWeight={400}
              mt="12px"
              textAlign="center"
            >
              {message}
            </Text>
          </Box>
        </VStack>
        <ModalFooter justifyContent="space-between" gap="16px" mt="43px">
          <Button
            variant="secondary"
            w="100%"
            onClick={async () => {
              if (txHash) {
                window.open(`${explorer?.url}/tx/${txHash}`);
              } else {
                router.push('/positions');
              }
            }}
          >
            {txHash ? 'View on explorer' : 'Your positions'}
          </Button>
          <Button
            variant="primary"
            w="100%"
            onClick={async () => {
              onClose();
            }}
          >
            {status === 'success' ? 'Done' : 'Go back'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
