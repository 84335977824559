import { ChakraProps, Input } from '@chakra-ui/react';
import fromExponential from 'from-exponential';
import { debounce } from 'lodash';
import React, {
  FC,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

interface Props extends ChakraProps {
  amount: string;
  onChange?: (val: string) => void;
  onBlur?: () => void;
  readOnlyValue?: string;
  disabled?: boolean;
  textAlign?: 'left' | 'right';
  placeholder?: string;
  type?: 'text' | 'number';
  color?: string | undefined;
  onlyNumbers?: boolean;
}

const useDebounce = (callback: () => void) => {
  /* eslint-disable */
  // @ts-ignore
  const ref = useRef<any>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  return useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, 700);
  }, []);
};

export const DebouncedInput: FC<Props> = ({
  amount,
  onChange,
  onBlur,
  disabled,
  readOnlyValue,
  textAlign = 'right',
  placeholder,
  type = 'number',
  color = 'neutral.400',
  onlyNumbers,
  ...rest
}) => {
  const [value, setValue] = useState(amount);

  const onChangeHandler = () => {
    onChange?.(value);
  };

  const debouncedOnChange = useDebounce(onChangeHandler);

  const handleKeyPress = useCallback<KeyboardEventHandler>(
    e => {
      if (type === 'text' && onlyNumbers) {
        const validChar = /^[0-9.]$/.test(e.key);
        if (!validChar) {
          e.preventDefault();
        }
      }
    },
    [onlyNumbers, type]
  );

  const handleSearchKeyup = useCallback<KeyboardEventHandler>(
    e => {
      if (e.key === 'Enter') {
        debouncedOnChange.flush();
      } else if (e.key === 'Escape') {
        debouncedOnChange.cancel();
        setValue('');
        onChange?.('');
      }
    },
    [debouncedOnChange]
  );

  useEffect(() => {
    if (value !== amount) {
      setValue(amount);
    }
  }, [amount]);

  return (
    <Input
      variant="filled"
      borderRadius={4}
      w="100%"
      bg="transparent"
      fontSize="24px"
      fontWeight="400"
      lineHeight="1"
      letterSpacing="-0.96px"
      color={color ? color : readOnlyValue ? color : 'white'}
      _focusVisible={{
        outlineColor: 'transparent',
        outlineWidth: '2px',
        outlineOffset: '2px',
        outlineStyle: 'dotted'
      }}
      _hover={{ bg: 'transparent' }}
      px={0}
      textAlign={textAlign}
      type={type ?? 'number'}
      formNoValidate
      lang="en"
      step="any"
      min={0}
      value={
        readOnlyValue !== undefined
          ? fromExponential(Number(readOnlyValue))
          : value
      }
      onKeyPress={handleKeyPress}
      onKeyUp={handleSearchKeyup}
      onChange={e => {
        debouncedOnChange();
        setValue(e.target.value);
      }}
      onBlur={onBlur}
      disabled={disabled || !onChange}
      placeholder={placeholder ?? '0.00'}
      _placeholder={{ color: 'neutral.400' }}
      sx={{
        padding: '8px 12px',
        fontFamily: 'p'
      }}
      {...rest}
    />
  );
};
