import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, useDisclosure, Grid, Text, HStack } from '@chakra-ui/react';
import React, { FC, useState } from 'react';

import {
  TokenSelectorModal,
  PoolsTokenSelectorModal
} from '@app/components/Swap/components/TokenSelectorModal';
import { TokenIcon } from '@app/components/TokenIcon';
import { Token } from '@app/types/token';
import { getCurrencySymbol } from '@app/helpers/currency';

interface Props {
  selected: Token | null;
  onSelect: (val: Token) => void;
  disabled: boolean;
  filterByPools?: boolean;
  isV3?: boolean;
}

export const TokenSelector: FC<Props> = ({
  selected,
  disabled,
  onSelect,
  filterByPools,
  isV3
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        onClick={onOpen}
        isDisabled={disabled}
        minWidth="110px"
        width="fit-content"
        h="100%"
        bg="neutral.400"
        _hover={{
          filter: 'brightness(120%)'
        }}
        px="12px"
        py="6px"
        borderRadius={8}
        sx={{ cursor: disabled ? 'default' : 'pointer' }}
      >
        <Grid
          gridTemplateColumns="24px 1fr"
          gridColumnGap="6px"
          gridRowGap={0}
          w="100%"
          alignItems="center"
        >
          <TokenIcon
            symbol={getCurrencySymbol(selected)}
            iconUrl={selected?.logoUri}
          />
          <HStack>
            <Text
              fontSize="16px"
              fontWeight="400"
              lineHeight="20px"
              color="white"
              fontFamily="p"
              // textTransform="capitalize"
              textAlign="left"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {selected ? getCurrencySymbol(selected) : '-'}
            </Text>
            {!disabled && <ChevronDownIcon />}
          </HStack>
        </Grid>
      </Button>
      {filterByPools ? (
        <PoolsTokenSelectorModal
          onClose={onClose}
          isOpen={isOpen}
          onSelect={onSelect}
          isV3={isV3}
        />
      ) : (
        <TokenSelectorModal
          onClose={onClose}
          isOpen={isOpen}
          onSelect={onSelect}
        />
      )}
    </>
  );
};
