import { ConnectWallet } from '@thirdweb-dev/react';
import React from 'react';

import { ConnectWalletWelcomeScreen } from '@app/components/AppHeader/components/ConnectWalletWelcomeScreen';

import s from './CustomConnectWalletButton.module.scss';

export const CustomConnectWalletButton = () => {
  return (
    <ConnectWallet
      switchToActiveChain
      theme="dark"
      modalSize="wide"
      className={s.root}
      // style={{
      //   backgroundColor: 'var(--brand-white)',
      //   color: 'var(--brand-black)'
      // }}
      welcomeScreen={() => {
        return <ConnectWalletWelcomeScreen />;
      }}
      detailsBtn={() => <div />}
    />
  );
};
