import { Box, Button, VStack } from '@chakra-ui/react';
import cn from 'clsx';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { cssTransition, toast } from 'react-toastify';

import { Icon, IconName } from '@app/components/Icon';

import s from './Toast.module.scss';

interface Props extends PropsWithChildren {
  status: 'info' | 'error' | 'warning' | 'success';
  text: string;
  closeToast?: () => void;
}

export const backInOut = cssTransition({
  enter: 'animate__animated animate__backInRight',
  exit: 'animate__animated animate__backOutUp'
});

export const Toast: FC<Props> = ({ status, text, closeToast, children }) => {
  let iconName;
  let title;

  switch (status) {
    case 'warning': {
      title = 'Warning';
      iconName = 'warning';
      break;
    }
    case 'error': {
      title = 'Error';
      iconName = 'error';
      break;
    }
    case 'success': {
      title = 'Success';
      iconName = 'checkCircle';
      break;
    }
    default: {
      title = 'Info';
      iconName = 'info';
    }
  }

  return (
    <Box className={s.root}>
      <Box
        className={cn(s.header, {
          [s.info]: status === 'info',
          [s.success]: status === 'success',
          [s.error]: status === 'error',
          [s.warning]: status === 'warning'
        })}
      >
        <div className={s.titleWrapper}>
          <Icon name={iconName as IconName} className={s.icon} /> {title}
        </div>
        <Button
          variant="transparent"
          onClick={closeToast}
          className={s.closeBtn}
        >
          <Icon name="close" />
        </Button>
      </Box>
      <VStack className={s.body}>
        <Box>{text}</Box>
        {children}
      </VStack>
    </Box>
  );
};

export function showSuccessToast(msg: string, children?: ReactNode) {
  toast(
    <Toast status="success" text={msg}>
      {children}
    </Toast>,
    {
      transition: backInOut
    }
  );
}

export function showErrorToast(msg: string) {
  toast(<Toast status="error" text={msg} />, {
    transition: backInOut
  });
}
