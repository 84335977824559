import { NATIVE_TOKEN_ADDRESS } from '@thirdweb-dev/sdk';

import { TokenResponseItem } from '@app/hooks/swap/types';
import { Token } from '@app/types/token';
import { PairToken } from '@app/types/pool';
import { ApplicationConfig } from '@app/config/types';
import { getTokensWhitelist } from '@app/constants/tokensWhitelist';

function getCoinIcon(item: TokenResponseItem) {
  if (item.icon_url) {
    return item.icon_url;
  }

  return '';
}

export function getWhitelistedCoinIcon(item: Token) {
  if (item.logoUri) {
    return item.logoUri;
  }

  return '';
}

export function mapTokenResponseItem(item: TokenResponseItem): Token {
  return {
    symbol: item.symbol,
    logoUri: getCoinIcon(item),
    decimals: Number(item.decimals),
    name: item.name,
    contractAddress: item.address
  };
}

export function mapPairTokenToToken(
  pairToken: PairToken,
  config: ApplicationConfig | undefined
): Token {
  return {
    contractAddress: pairToken.id,
    logoUri: '',
    name: pairToken.name,
    symbol: pairToken.symbol,
    decimals: +pairToken.decimals,
    whitelisted: getTokensWhitelist(config)
      .map(t => t.contractAddress.toLowerCase())
      .includes(pairToken.id.toLowerCase())
  };
}

export function isEthWethSwap(
  from: Token,
  to: Token,
  config: ApplicationConfig | undefined
) {
  return (
    (from.contractAddress === NATIVE_TOKEN_ADDRESS &&
      to.contractAddress === config?.CONTRACTS.WETH) ||
    (to.contractAddress === NATIVE_TOKEN_ADDRESS &&
      from.contractAddress === config?.CONTRACTS.WETH)
  );
}
