import { NATIVE_TOKEN_ADDRESS } from '@thirdweb-dev/sdk';

import { Token } from '@app/types/token';
import { ModeEnvs } from '@app/constants/chains';
import { ApplicationConfig } from '@app/config/types';

// export const DEFAULT_WHITELIST: Token[] = [
//   {
//     symbol: 'ETH',
//     contractAddress: NATIVE_TOKEN_ADDRESS,
//     decimals: 18,
//     logoUri: '',
//     name: 'ETH'
//   },
//   {
//     symbol: 'KIM',
//     contractAddress: '0x6863fb62Ed27A9DdF458105B507C15b5d741d62e',
//     name: 'KIM Token',
//     decimals: 18,
//     logoUri: ''
//   },
//   {
//     symbol: 'MODE',
//     contractAddress: '0xDfc7C877a950e49D2610114102175A06C2e3167a',
//     name: 'Mode',
//     decimals: 18,
//     logoUri: ''
//   }
// ];

// export const TOKENS_WHITELIST_TESTNET: Token[] = JSON.parse(
//   config.tokensWhitelist
// );
//
// export const TOKENS_WHITELIST_MAINNET: Token[] = JSON.parse(
//   config.tokensWhitelist
// );

// export const TOKENS_WHITELIST =
//   config.modeEnv === ModeEnvs.MAINNET
//     ? [...DEFAULT_WHITELIST, ...TOKENS_WHITELIST_MAINNET]
//     : [...DEFAULT_WHITELIST, ...TOKENS_WHITELIST_TESTNET];

export function getTokensWhitelist(config: ApplicationConfig | undefined) {
  return config?.CONTENT.tokensWhitelist ?? [];
}

export const POPULAR_BASES = [
  'ETH',
  'USDT',
  'USDC',
  'WETH',
  'WBTC',
  'KIM',
  'MODE'
];

// export const wlTokensIds = TOKENS_WHITELIST.map(t =>
//   t.contractAddress.toLowerCase()
// );

// export const wlTokensSymbols = TOKENS_WHITELIST.map(t =>
//   t.symbol.toLowerCase()
// );

// export function isTokenWhitelisted(address: string) {
//   return wlTokensIds.includes(address.toLowerCase());
// }

// export function isTokenFake(symbol: string, address: string) {
//   return (
//     wlTokensSymbols.includes(symbol.toLowerCase()) &&
//     !wlTokensIds.includes(address.toLowerCase())
//   );
// }
